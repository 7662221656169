import {Select, Spin} from 'antd';
import debounce from 'lodash/debounce';
import React, {useMemo, useRef, useState} from 'react';

function DebounceSelect({fetchOptions, debounceTimeout = 800, ...props}) {
  let [options, setOptions] = useState([]);
  let [page, setPage] = useState(1);
  const [fetching, setFetching] = useState(false);

  const fetchRef = useRef(0);
  const offset = 10;

  const onPopupScroll = (e) => {
    e.persist();
    let target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      page ++;
      setPage(page)
      fetchOptions("", page, 10).then((res) => {
        setOptions([...options, ...res]);
      })
    }
  }

  let args = []
  if (props.service === "amofiles") {
    args.push("", page, offset)
    props.onPopupScroll = onPopupScroll
  }

  const debounceFetcher = useMemo(() => {
    fetchOptions(...args).then((res) => {
      setOptions(res)
    })

    const loadOptions = (value) => {
      setPage(1)
      let _args = args.slice()
      _args[0] = value
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(..._args).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);

        if (props.service === "productlab") {
          let obj = options.find(ob => ob.label === value);

          if (!obj && value) {
            setOptions([...newOptions, {label: `${value} (Новый)`, value: value, title: 'new'}])
          }
        }
      });

    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small"/> : null}
      {...props}
      options={options}
    />
  );
}

export default DebounceSelect;