import React, {Component} from "react";
import {Button, Form, Input, Modal, Space} from "antd";
import {LoginOutlined, RobotOutlined} from "@ant-design/icons";
import axios from "axios";

class NoTokenPage extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.state = {
      loaded: false,
      bot_info: {}
    }
  }

  onFinish = (values) => {
    let queryParams = new URLSearchParams(window.location.search)
    queryParams.set("token", values.token)
    this.props.refresh(values.token, 0, 0, "/categories")
  }

  getBotInfo = () => {
    axios.get("https://amofiles.coffeeretail.ru/api/bot_info")
      .then(response => {
        if (response.status === 200) {
          this.setState({loaded: true, bot_info: response.data})
        }
      })
  }

  goBot = () => {
    window.open(`https://t.me/${this.state.bot_info.username}`, '_blank').focus();
  }

  componentDidMount() {
    this.getBotInfo();
  }

  render() {
    return (this.state.loaded ? (<Modal
      title="Неверный токен"
      open={true}
      footer={[]}
      closable={false}
      centered
    >
      <p>Для продолжения, укажите токен авторизации</p>
      <Form
        name="auth_form"
        ref={this.formRef}
        onFinish={this.onFinish}
      >
        <Form.Item
          label="Токен"
          name="token"
          rules={[{
            required: true, message: "Вставьте токен!",
          }]}
        >
          <Input placeholder="Токен"/>
        </Form.Item>
        <Space style={{width: "100%", justifyContent: "space-between"}}>
          <Button icon={<RobotOutlined/>} onClick={this.goBot}>Перейти в бота</Button>
          <Button icon={<LoginOutlined/>} htmlType="submit">Войти</Button>
        </Space>
      </Form>
    </Modal>) : null)
  }
}

export default NoTokenPage;