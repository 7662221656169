import {Card, Row, Pagination} from 'antd';
import React from 'react';
import axios from 'axios';
import no_ava from '../../images/no_ava.png'
import UserEdit from './Edit';
import SendingCreate from "./Sending";

const {Meta} = Card;

class UserList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    }
  }

  ws_connect = () => {
    this.websocket = new WebSocket(`wss://amofiles.coffeeretail.ru/ws/article`)


    this.websocket.onmessage = message => {
      const data = JSON.parse(message.data)

      let new_user_info = null

      if (data.type === "UpdateUser" || data.type === "CreateNewUser") {
        let profile_pic = 'https://amofiles.coffeeretail.ru/api/' + data.data.profile_pic
        let role = data.data.role

        let ru_role = ""

        if (!data.data.profile_pic) {
          profile_pic = no_ava
        }

        if (role === "ADMIN") {
          ru_role = "Администратор"
        }
        if (role === "MANAGER") {
          ru_role = "Менеджер"
        }
        if (role === "COPYWRITER") {
          ru_role = "Копирайтер"
        }

        new_user_info = <Card
          key={data.data.id}
          style={{
            width: 300,
            marginBottom: 15,
            marginLeft: 5
          }}
          cover={
            <img
              alt="card_image"
              src={profile_pic}
            />
          }
          actions={[
            <UserEdit token={this.props.token} user_info={data.data} profile_pic={profile_pic}/>,
          ]}
        >

          <Meta
            title={data.data.name}
          />
          <br/>
          {<b>{ru_role}</b>}
          <br/>
          {<i>{data.data.about}</i>}
        </Card>
      }

      if (data.type === "CreateNewUser") {
        const dataSource = [...this.state.Cards]
        let newDS = []
        if (dataSource.length >= 17) {
          newDS = dataSource.slice(0, 17)
        } else {
          newDS = dataSource
        }

        newDS.unshift(new_user_info)

        let card_c = this.state.CardsC + 1
        this.setState({Cards: newDS, CardsC: card_c})

      }

      if (data.type === "UpdateUser") {
        const newData = [...this.state.Cards];
        const index = newData.findIndex((item) => data.id.toString() === item.key);

        if (index !== -1) {
          // const item = newData[index];
          newData.splice(index, 1, new_user_info)
          this.setState({Cards: newData});
        }
      }
    }
  }

  componentWillUnmount() {
    this.websocket.close()
    this.websocket = null
  }

  fetch = (page, url = {}) => {
    const limit = 18
    const offset = (page * 18) - 18

    axios({
      url: url,
      method: 'get',
      params: {
        limit: limit,
        offset: offset,
      },
      headers: {
        'token': this.props.token
      },
    })
      .then(response => {
        let cards = []
        for (var i = 0; i < response.data.result.length; i++) {
          let profile_pic = 'https://amofiles.coffeeretail.ru/api/' + response.data.result[i].profile_pic
          let role = response.data.result[i].role

          let ru_role = ""

          if (!response.data.result[i].profile_pic) {
            profile_pic = no_ava
          }

          if (role === "ADMIN") {
            ru_role = "Администратор"
          }
          if (role === "MANAGER") {
            ru_role = "Менеджер"
          }
          if (role === "COPYWRITER") {
            ru_role = "Копирайтер"
          }

          cards.push(
            // <Col key={response.data[i].id} span={4}>
            <Card
              key={response.data.result[i].id}
              style={{
                width: 300,
                marginBottom: 15,
                marginLeft: 5
              }}
              cover={
                <img
                  alt="card_image"
                  src={profile_pic}
                />
              }
              actions={[
                <UserEdit token={this.props.token} user_info={response.data.result[i]} profile_pic={profile_pic}/>,
              ]}
            >

              <Meta
                // avatar={<Avatar src={profile_pic} />}
                title={response.data.result[i].name}
              />
              <br/>
              {<b>{ru_role}</b>}
              <br/>
              {<i>{response.data.result[i].about}</i>}
            </Card>
            // </Col>
          )
        }
        this.setState({Cards: cards, CardsC: response.data.count, loaded: true})
      });
  }

  componentDidMount() {
    this.fetch(1, 'https://amofiles.coffeeretail.ru/api/users')
    this.ws_connect()
  }

  render() {
    return (
      this.state.loaded ? <div>
        <SendingCreate token={this.props.token}/>
        <Row>
          {this.state.Cards}
        </Row>
        <Pagination
          onChange={(page) => {
            this.fetch(page, 'https://amofiles.coffeeretail.ru/api/users')
          }}
          pageSize={18}
          total={this.state.CardsC}
          style={{float: "right"}}
          showTotal={(total, range) => `Показаны ${range[0]}-${range[1]} из ${total} пользователей`}
        />
      </div> : null
    )
  }
}

export default UserList;