import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import axios from "axios";

import {Row, Card, Upload, message, Button, Pagination} from "antd";
import {EyeOutlined, PictureOutlined} from "@ant-design/icons";
import no_image from "../../images/no_main.png";
import UserAccess from "../users/Access";

const {Meta} = Card;

const FileCategories = ({click, checked, onChange, token}) => {
  const [categories, setCategories] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [countCategories, setCountCategories] = useState(0)
  const [loaded, setLoaded] = useState(false)

  let navigation = useNavigate()
  let location = useLocation()
  let queryParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (location.pathname === "/categories" && queryParams.get("category")) {
      navigation(location.pathname.replace("categories", "") + location.search)
      click(true)
    }

    fetchCategories(page, pageSize)
  }, [])


  const onClickCard = (item) => {
    click(!checked)
    onChange(item.id)
  }

  const changeCategory = (category) => {
    setCategories(categories.map(el => el.id === category.id ? category : el))
  }

  const fetchCategories = (page, limit) => {
    axios.get("https://amofiles.coffeeretail.ru/api/files/category", {headers: {token: token}, params: {page: page, limit: limit}})
      .then(response => {
        if (response.status === 200) {
          setCategories(response.data.categories)
          setCountCategories(response.data.all_count)
          setLoaded(true)
        }
      })
  }

  const onImageUpload = () => {
    setLoaded(false)
    fetchCategories()
  }

  const paginationChange = (page, pageSize) => {
    setLoaded(false)
    setPage(page)
    setPageSize(pageSize)
    fetchCategories(page, pageSize)
  }

  return (<>
    {loaded ? (<div>
      <Row>
        {categories.map((item) => {
          return <Card
            key={item.id}
            style={{
              width: 300, marginBottom: 15, marginLeft: 5
            }}
            cover={<img
              style={{width: 300, height: 300}}
              alt="card_cover"
              src={item.image ? `https://amofiles.coffeeretail.ru/api/${item.image}` : `${no_image}`}
            />}
            actions={[<Button icon={<EyeOutlined/>} type={"text"} onClick={() => onClickCard(item)}/>, <Upload
              {...{
                name: "image",
                action: `https://amofiles.coffeeretail.ru/api/files/category/${item.id}/image`,
                headers: {},
                maxCount: 1,
                showUploadList: false,
                onChange(info) {
                  if (info.file.status === "done") {
                    onImageUpload()
                    message.success("Изображение категории было изменено");
                  } else if (info.file.status === "error") {
                    message.error("Ошибка изменения изображения");
                  }
                }
              }}
            >
              <Button icon={<PictureOutlined/>} type={"text"}/>
            </Upload>,
              <UserAccess token={token} category_id={item.id} defaultValues={item.users} change={changeCategory}/>]}
          >
            <Meta
              title={item.name}
              description={`Количество файлов: ${item.count_files}`}
            />
          </Card>
        })}
      </Row>
      <Pagination
        defaultCurrent={page}
        total={countCategories}
        pageSize={pageSize}
        onChange={paginationChange}
        style={{float: "right"}}
      />
    </div>) : null}
  </>)
}

export default FileCategories;