import React, {Component} from 'react';
import {Button, Form, Modal, Input, Checkbox, message, Space} from "antd";
import {SendOutlined} from "@ant-design/icons";
import DebounceSelect from "../../DebFetch";
import axios from "axios";

const {TextArea} = Input;

class SendingCreate extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      isModalOpen: false
    }
  }

  showModal = () => {
    this.setState({
      isModalOpen: true
    });
  }

  handleCancel = () => {
    this.setState({
      isModalOpen: false
    });
  }

  onFinish = (values) => {
    const categories_ids = values.fetch_categories && values.fetch_categories.length !== 0 ? values.fetch_categories.map((tag) => tag.value) : [];
    const tags_ids = values.fetch_tags && values.fetch_tags.length !== 0 ? values.fetch_tags.map((tag) => tag.value) : [];

    axios.post(`https://amofiles.coffeeretail.ru/api/users/sending`, {
      "text": values.text,
      "skipBlocked": values.skipBlocked,
      "categories_ids": categories_ids,
      "tags_ids": tags_ids
    }, {
      headers: {"token": this.props.token}
    }).then(response => {
      this.setState({
        isModalOpen: false
      });
      message.success("Вы успешно запустили рассылку")
    })
      .catch((error) => message.error(`Ошибка ${error.response.status}`));
  }

  fetch = async (link, param) => {
    link = param ? link + `?name=${param}` : link
    return fetch(link, {headers: {"token": this.state.token}})
      .then((response) => response.json())
      .then((body) => {
        return body
      })
      .then((body) =>
        body.items.map((_param) => ({
            label: `${_param.name}`,
            value: _param.id
          })
        )
      )
      .then((body) => {
        return body
      })
  }

  fetchCategories = async (category) => {
    return await this.fetch("https://amofiles.coffeeretail.ru/api/category_for_user/", category)
  }

  fetchTags = async (tag) => {
    return await this.fetch("https://amofiles.coffeeretail.ru/api/tag_for_user/", tag)
  }

  render() {
    return (
      <>
        <Button icon={<SendOutlined/>} style={{marginBottom: 15, marginLeft: 5}}
                onClick={this.showModal}>Рассылка</Button>
        <Modal destroyOnClose onCancel={this.handleCancel} footer={null} width={600}
               title={`Создание рассылки`} open={this.state.isModalOpen}>
          <Form
            name="create_sending"
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{"skipBlocked": true}}
          >
            <Form.Item
              label="Текст"
              name="text"
              rules={[{required: true, message: 'Текст не может быть пустым!'}]}
            >
              <TextArea placeholder="Введите текст рассылки"/>
            </Form.Item>
            <Form.Item
              label="Категории"
              name="fetch_categories"
            >
              <DebounceSelect
                mode="multiple"
                placeholder="Выберите категории"
                fetchOptions={this.fetchCategories}
                removeIcon={null}
                onChange={(newValue) => {
                  this.formRef.current.setFieldsValue({
                    fetch_categories: newValue,
                  })
                }}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label="Тэги"
              name="fetch_tags"
            >
              <DebounceSelect
                mode="multiple"
                placeholder="Выберите тэги"
                fetchOptions={this.fetchTags}
                removeIcon={null}
                onChange={(newValue) => {
                  this.formRef.current.setFieldsValue({
                    fetch_tags: newValue,
                  })
                }}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Space style={{width: "100%", justifyContent: "space-between"}}>
              <Form.Item
                label="Пропустить заблокированных"
                name="skipBlocked"
                valuePropName="checked"
              >
                <Checkbox/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Отправить
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Modal>
      </>
    )
  }
}

export default SendingCreate;