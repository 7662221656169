import React, {useState} from "react";

import {Button, Form, Modal, Space, message} from "antd";
import {UserOutlined} from "@ant-design/icons";
import DebounceSelect from "../../DebFetch";
import axios from "axios";

const UserAccess = ({token, category_id, file_id, defaultValues, change}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = React.createRef();

  const formItemLayout = {
    labelCol: {
      span: 4,
    }, wrapperCol: {
      span: 20,
    },
  };

  let _defaultValues = defaultValues?.map((_user) => ({label: `${_user.name}`, value: _user.id}))

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    const formData = new FormData();
    let url = "https://amofiles.coffeeretail.ru/api/files/";
    url = url + (category_id || category_id === 0 ? `category/access/${category_id}` : `access/${file_id}`)

    if (values.fetch_users?.length !== 0) {
      for (var i = 0; i < values.fetch_users.length; i++) {
        formData.append("user_ids", values.fetch_users[i].value);
      }
    } else {
      formData.append("user_ids", 0)
    }
    axios.post(url, formData, {headers: {token: token}})
      .then((response) => {
        message.success("Вы успешно прикрепили пользователей!");
        change(response.data);
        setIsModalOpen(false);
      })
      .catch(error => message.error(`Ошибка ${error.response.status}`));
  }

  const fetchUsers = async (name) => {
    const link = "https://amofiles.coffeeretail.ru/api/users"
    return fetch(name ? link + `?name=${name}` : link, {headers: {"token": token}})
      .then((response) => response.json())
      .then((body) => {
        return body
      })
      .then((body) => body.result.filter((_user) => _user.role !== "ADMIN").map((_user) => ({
        label: `${_user.name}`, value: _user.id
      })))
      .then((body) => {
        return body
      })
  };

  return (<>
    <Button icon={<UserOutlined/>} type={"text"} onClick={showModal}/>
    <Modal title="Привязка пользователей" open={isModalOpen} onCancel={handleCancel} footer={null}
           destroyOnClose={true}>
      <Form
        name="validate_other"
        {...formItemLayout}
        ref={formRef}
        initialValues={{"fetch_users": _defaultValues}}
        onFinish={onFinish}
      >
        <Form.Item
          label="Пользователи"
          name="fetch_users"
        >
          <DebounceSelect
            mode="multiple"
            placeholder="Выберите пользователя"
            fetchOptions={fetchUsers}
            removeIcon={null}
            onChange={(newValue) => {
              formRef.current.setFieldsValue({
                fetch_users: newValue
              })
            }}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
        <Space style={{width: "100%", justifyContent: "space-between"}}>
          <Button htmlType="button" onClick={handleCancel}>
            Отмена
          </Button>
          <Button type="primary" htmlType="submit" style={{marginRight: 5}}>
            Подтвердить
          </Button>
        </Space>
      </Form>
    </Modal>
  </>)
}

export default UserAccess;