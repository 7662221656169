import './App.css'
import React, {useState, useEffect} from 'react';
import {Routes, Route, Link, useNavigate, useLocation} from "react-router-dom";

import {
  UnorderedListOutlined, UserOutlined,
} from '@ant-design/icons';
import {Switch} from 'antd';

import {Layout, Menu} from 'antd';

import axios from 'axios';
import UserList from './components/users/List';
import FileList from './components/files/List';
import DismissedPage from "./pages/Dismissed";
import FileCategories from "./components/files/Categories";
import NoTokenPage from "./pages/NoTokenPage";

const {Header, Content, Footer} = Layout;

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const App = () => {
  const [check, setCheck] = useState(false)
  const [userId, setUserId] = useState(false)

  const defaultNavItems = [{
    label: <Link to={params.token ? `/?token=${params.token}` : `/`}>Файлы</Link>,
    key: 'files',
    icon: <UnorderedListOutlined/>
  },]

  const [state, setState] = useState({
    loaded: false, routes: <Routes></Routes>, nav_items: defaultNavItems, user: false
  })

  let navigation = useNavigate()
  let location = useLocation()
  let queryParams = new URLSearchParams(location.search)

  const setUserPermission = (token, categoryId = 0, fileCategoryId = 0, redirection) => {
    axios.get(`https://amofiles.coffeeretail.ru/api/user/${token}`)
      .then(response => {
        let routes = <Routes></Routes>
        let navItems = defaultNavItems
        setUserId(response.data)

        if (response.data.role === "ADMIN") {
          routes = (<Routes>
            <Route
              path="/"
            >
              <Route index element={<FileList token={params.token} category={fileCategoryId}/>}/>
              <Route path={"categories"} element={<FileCategories click={setCheck} checked={check} onChange={onChange} token={token}/>}/>
            </Route>

            <Route
              path="/users"
              element={<UserList token={params.token}/>}
            />
          </Routes>)
          navItems = [defaultNavItems[0], {
            label: <Link to={`/users?token=${params.token}`}>Пользователи</Link>, key: 'users', icon: <UserOutlined/>
          }]
        } else {
          routes = (<Routes>
            <Route
              path="/"
            >
              <Route index element={<FileList token={params.token} category={fileCategoryId}/>}/>
              <Route path={"categories"} element={<FileCategories click={setCheck} checked={check} onChange={onChange} token={token}/>}/>
            </Route>
          </Routes>)
        }

        setState({
          ...state, loaded: true, user: response.data, routes: routes, nav_items: navItems
        })
        if (redirection) {
          queryParams.set("token", token)
          navigation(redirection + "?" + queryParams.toString())
        }
      })
      .catch(error => {
        setState({
          ...state, routes: <Routes>
            <Route
              path="/403"
              element={<NoTokenPage refresh={setUserPermission}/>}
            />
          </Routes>
        })
        navigation("/403" + location.search)
      })
  }

  useEffect(() => {
    // if (location.pathname === "/") {
    //   navigation(location.pathname + "categories" + location.search)
    //   setCheck(true)
    // }
    setUserPermission(params.token)
  }, [])

  const onChange = (id) => {
    queryParams.set("category", id)
    window.location.search = queryParams.toString()
    setUserPermission(params.token, id, 0, "/")
  }

  const onSwitch = () => {
    queryParams.delete("category")
    setCheck(!check)
    navigation(!check ? "/?token=" + queryParams.get("token") : "/categories?token=" + queryParams.get("token"))
  }

  let selected = []
  selected = []

  return (state.user?.is_dismissed ? (<DismissedPage/>) : (<Layout>
    <Header
      style={{
        position: 'fixed',
        zIndex: 2,
        width: '100%',
        background: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Menu
        theme="light"
        mode="horizontal"
        defaultSelectedKeys={selected}
        items={state.nav_items}
        style={{width: '100%'}}
      />

      <div style={{
        display: 'flex', alignItems: 'center', marginLeft: 'auto', gap: '10px',
      }}>
        <span>Категории</span>
        <Switch checked={check} onChange={onSwitch}/>
        <span>Файлы</span>
      </div>
    </Header>

    <Content
      className="site-layout"
      style={{
        padding: '10px 20px', marginTop: 64,
      }}
    >
      <div
        className="site-layout-background"
        style={{
          padding: 15, minHeight: "83.5vh",
        }}
      >
        {state.routes}
      </div>
    </Content>

    <Footer
      style={{
        textAlign: 'center',
      }}
    >
      2022 Made by @productlab.pro/blog/team
    </Footer>
  </Layout>))
}

export default App;
