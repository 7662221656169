import React from 'react';
import axios from 'axios';
import {DeleteOutlined, SearchOutlined} from '@ant-design/icons';
import {Table, message, Popconfirm, Tag, Input, Space} from 'antd';
import FileForm from "./Form";
import DebounceSelect from "../../DebFetch";
import UserAccess from "../users/Access";

class FileList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      count: 0,
      pages: 0,
      loaded: false,
      page: 1,
      size: 10,
      tags: [],
      category: [],
      description: ""
    }
    this.queryParams = new URLSearchParams(window.location.search)
  }

  remove_file = (rec) => {
    axios({
      url: `https://amofiles.coffeeretail.ru/api/files/${rec.id}`,
      method: 'delete',
      headers: {
        token: this.props.token
      },
    }).then(response => {
      message.success('Файл удалён')
      this.fetch(this.state.page, this.state.size)
    })
  }

  fetchTags = (name, page, limit) => {
    return axios({
      url: `https://amofiles.coffeeretail.ru/api/files/tag`,
      method: 'get',
      params: {
        name: name,
        page: page,
        limit: limit
      },
    }).then(response => {
      return response.data.map((tag) => ({
        label: `${tag.name}`,
        value: tag.id,
      }))
    }).catch(error => [])
  }

  changeFile = (file) => {
    this.setState({
      ...this.state, files: this.state.files.map(el => el.id === file.id ? file : el)
    })
  }

  columns = () => ([
    {title: 'Id', dataIndex: 'id', key: 'id'},
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (text, rec) => rec.is_deleted ? text :
        <a href={rec.link} target="_blank" rel="noopener noreferrer">{text}</a>
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
        <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
          <Space>
            <Input value={this.state.description} onChange={(e) => {
              this.setState({description: e.target.value})
            }}/>
            <SearchOutlined onClick={() => {
              this.fetch(1, this.state.size);
            }}/>
          </Space>
        </div>
      )
    },
    {
      title: 'Тэги',
      key: 'tags',
      dataIndex: 'tags',
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
        <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
          <Space>
            <DebounceSelect
              mode="multiple"
              placeholder="Выберите тэги"
              fetchOptions={this.fetchTags}
              removeIcon={null}
              allowClear={true}
              value={this.state.tags}
              onChange={(val) => this.setState({...this.state, tags: val})}
              service={"amofiles"}
              style={{width: '180px'}}
            />
            <SearchOutlined onClick={() => {
              this.fetch(1, this.state.size);
            }}/>
          </Space>
        </div>
      ),
      render: (_, {tags}) => (
        <>
          {tags.map((tag) => {
            return (
              <Tag key={tag.id}>
                {tag.name.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {title: 'Тип', dataIndex: 'type', key: 'type'},
    {title: 'Размер', dataIndex: 'file_size', key: 'file_size', render: val => Math.floor(val)},
    {
      title: 'Создан',
      dataIndex: 'created_at',
      key: 'created_at',
      render: val => val ? new Date(val).toLocaleString('ru-ru') : null
    },
    {
      title: 'Доступ', dataIndex: 'access', key: 'access', render: (val, rec) => (
        <UserAccess token={this.props.token} file_id={rec.id} defaultValues={rec.users} change={this.changeFile}/>
      )
    },
    {
      title: 'Удалить', dataIndex: 'id', key: 'id', render: (val, rec) => rec.is_deleted ? null : (
        <Popconfirm
          title={`Вы уверены, что хотите удалить файл "${rec.name}"?`}
          placement="topLeft"
          onConfirm={() => this.remove_file(rec)}
          onCancel={() => message.info('Удаление отменено')}
        >
          <DeleteOutlined/>
        </Popconfirm>
      )
    }
  ])

  fetch = (page, size) => {
    this.setState(state => ({...state, page: page, loaded: false}))
    let url = "https://amofiles.coffeeretail.ru/api/files/";
    if (this.state.tags?.length) {
      this.queryParams.set("tags", JSON.stringify(this.state.tags))
      for (var t = 0; t < this.state.tags.length; t++) {
        if (url.slice(-1) === "/") {
          url = url + `?tags=${this.state.tags[t].value}`
        } else {
          url = url + `&tags=${this.state.tags[t].value}`
        }
      }
    }

    if (this.state.category?.length) {
      for (var c = 0; c < this.state.category.length; c++) {
        if (url.slice(-1) === "/") {
          url = url + `?category=${this.state.category[c].value}`
        } else {
          url = url + `&category=${this.state.category[c].value}`
        }
      }
    }

    if (this.state.description) {
      this.queryParams.set("description", this.state.description)
    }

    axios({
      url: url,
      method: 'get',
      params: {
        limit: size,
        page: page,
        description: this.state.description
      },
      headers: {
        token: this.props.token
      },
    }).then(response => {
      this.setState(state => ({...state, ...response.data, page: page, loaded: true}));
    })

    window.history.replaceState("", "", window.location.pathname + "?" + this.queryParams.toString());
  }

  componentDidMount() {
    if (this.queryParams.get("page") && this.queryParams.get("offset")) {
      this.state.page = parseInt(this.queryParams.get("page"))
      this.state.size = parseInt(this.queryParams.get("offset"))
    }

    if (this.queryParams.get("tags")) {
      this.state.tags = JSON.parse(this.queryParams.get("tags"))
    }

    if (this.queryParams.get("description")) {
      this.state.description = this.queryParams.get("description")
    }

    let categoryParam = this.queryParams.get("category")
    if (!!categoryParam) {
      this.state.category.push({value: categoryParam})
    }
    this.fetch(this.state.page, this.state.size)
  }

  componentWillUnmount() {
    this.queryParams.delete("page")
    this.queryParams.delete("offset")
    window.location.search = "?" + this.queryParams.toString()
  }

  setPaginationQuery = (page, pageSize) => {
    this.queryParams.set("page", page)
    this.queryParams.set("offset", pageSize)
    window.location.search = "?" + this.queryParams.toString()
  }

  table_change = (pag) => {
    this.setPaginationQuery(pag.current, pag.pageSize)
    this.setState(state => ({...state, size: pag.pageSize}))
    this.fetch(pag.current, pag.pageSize)
  }

  render() {
    return (
      <div>
        <FileForm token={this.props.token} fetchFiles={this.fetch} pageSize={this.state.size}/>
        {this.state.loaded ?
          (<Table
            columns={this.columns()}
            dataSource={this.state.files}
            rowKey={rec => rec.id}
            pagination={{
              current: this.state.page,
              pageSize: this.state.size,
              total: this.state.count
            }}
            style={{marginTop: 16}}
            onChange={(pag, val) => this.table_change(pag)}
          />) : null}
      </div>
    )
  }
}

export default FileList;
